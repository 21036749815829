<template>
  <v-snackbar
      :value="alertDialog.visible"
      :color="alertDialog.type"
      outlined
      centered
      transition="fade-transition"
      :multi-line=true
  >
    <div v-html="alertDialog.text"></div>
  </v-snackbar>
</template>

<script>
export default {
  name: "AlertDialog",
  computed: {
    alertDialog: {
      get() {
        return this.$store.state.alertDialog
      }
    }
  }
}
</script>

<style scoped>

</style>